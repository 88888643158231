import React from 'react';
import '../style/main.scss';

export default () => {
  return (
    <>
      <h1>Very Cool Stuff.</h1>
    </>
  );
};
